var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navigationSeventh"},[_c('div',{class:{
    nav: _vm.isDetail,
    navigator: !_vm.isDetail,
    nav2: _vm.isIndex
  }},[_c('div',{staticClass:"nav_left"},[_c('div',{staticClass:"logo"},[_c('el-image',{staticClass:"logo_img",attrs:{"src":_vm.isDetail ? _vm.domainInfo.videoLogo : _vm.domainInfo.logo},on:{"click":_vm.shouy}})],1),_c('div',{staticClass:"nav_item"},[(!_vm.menuList)?_c('el-menu',{staticClass:"el-menu-demo1",attrs:{"menu-trigger":"click","default-active":_vm.activeIndex,"mode":"horizontal"},on:{"select":_vm.handleSelect}},[_c('el-menu-item',{attrs:{"index":"0"}},[_c('div',{staticClass:"menu-box"},[_vm._v("资格证首页")])]),_c('el-menu-item',{attrs:{"index":"/agent/scroll"}},[_c('div',{staticClass:"menu-box"},[_c('el-popover',{attrs:{"placement":"top-start","width":255,"trigger":"hover"},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('div',{staticClass:"m-2 is-plain"},[_vm._v(" 资格证分类 "),_c('el-icon',{staticClass:"el-icon-arrow-down",class:{
                      down: _vm.isDetail,
                      down1: !_vm.isDetail,
                    }})],1)]},proxy:true}],null,false,3838569602)},_vm._l((_vm.popoverContent),function(item,index){return _c('div',{key:item.classifName,staticClass:"popover_item",class:{ hovered: item.hover },staticStyle:{"cursor":"pointer"},on:{"mouseover":function($event){return _vm.setHover(index, true)},"mouseout":function($event){return _vm.setHover(index, false)},"click":function($event){return _vm.certificateClick(index)}}},[_vm._v(" "+_vm._s(item.classifName)+" ")])}),0)],1)])],1):_c('el-menu',{class:_vm.i == 1 ? 'el-menu-demo ii' : 'el-menu-demo',attrs:{"menu-trigger":"click","default-active":_vm.activeIndex,"mode":"horizontal"},on:{"select":_vm.handleSelect}},[_c('el-menu-item',{staticClass:"ind",attrs:{"index":"/"}},[_c('div',{staticClass:"menu-box"},[_vm._v("首页")]),_c('div',{class:_vm.activeIndex == 0 ? 'is_active' : ''})]),_vm._l((_vm.menuList),function(item,idx){return _c('el-menu-item',{key:idx,staticClass:"ind",attrs:{"index":idx.toString()}},[_c('div',[_vm._v(_vm._s(item.certificateName))]),_c('div',{class:_vm.activeIndex == idx + 1 ? 'is_active' : ''})])})],2)],1)]),_c('div',{staticClass:"nav_hot"},[_c('div',{staticClass:"hot_line"},[(_vm.isDetail)?_c('el-image',{staticClass:"phone",attrs:{"src":require('@/assets/img/zige/phone2.png')}}):_vm._e(),(!_vm.isDetail)?_c('el-image',{staticClass:"phone2",attrs:{"src":require('@/assets/img/zige/phone2.png')}}):_vm._e()],1),_c('div',{staticClass:"line"},[_c('div',{class:{
          hour: _vm.isDetail,
          hour2: !_vm.isDetail,
        }},[_vm._v(" 24h客服热线 ")]),_c('div',{class:{
          phone_text: _vm.isDetail,
          phone_text2: !_vm.isDetail,
        }},[_vm._v(" "+_vm._s(_vm.domainInfo.mobile)+" ")])])])]),(_vm.isDetail)?_c('div',{staticClass:"nav_line"}):_vm._e(),(_vm.isDetail)?_c('div',{staticClass:"right_line"}):_vm._e(),_c('el-dialog',{staticClass:"loginDialog",attrs:{"close-on-click-modal":false,"title":"","visible":_vm.showLogin,"before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.showLogin=$event}}},[(_vm.showLogin)?_c('new-login',{key:_vm.newloginKey,on:{"toHome":_vm.toHome,"toRegister":_vm.toRegister,"showForgot":_vm.showForgot}}):_vm._e()],1),_c('el-dialog',{staticClass:"loginDialog",attrs:{"close-on-click-modal":false,"title":"","visible":_vm.showRegister,"before-close":_vm.handleCloseRegister},on:{"update:visible":function($event){_vm.showRegister=$event}}},[(_vm.showRegister)?_c('new-register',{key:_vm.registerKey,on:{"handleShowLogin":_vm.handleShowLogin}}):_vm._e()],1),_c('el-dialog',{staticClass:"loginDialog",attrs:{"title":"","close-on-click-modal":false,"width":"22%","visible":_vm.showForgotDialog,"before-close":_vm.handleCloseForgot},on:{"update:visible":function($event){_vm.showForgotDialog=$event}}},[_c('for-got',{key:_vm.forgotKey,on:{"dealForgot":_vm.dealForgot}})],1),_c('coupon-tips',{attrs:{"coupon-tips-visible":_vm.couponTipsVisible,"oupon-list":_vm.ouponList},on:{"couponTipsClose":_vm.couponTipsClose}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }