<template>
  <div class="navigationSeventh">
    <div :class="{
      nav: isDetail,
      navigator: !isDetail,
      nav2: isIndex
    }">
      <div class="nav_left">
        <div class="logo">
          <el-image class="logo_img" :src="isDetail ? domainInfo.videoLogo : domainInfo.logo" @click="shouy"></el-image>
        </div>
        <div class="nav_item">
          <el-menu v-if="!menuList" menu-trigger="click" :default-active="activeIndex" class="el-menu-demo1"
            mode="horizontal" @select="handleSelect">
            <el-menu-item index="0">
              <div class="menu-box">资格证首页</div>
            </el-menu-item>
            <el-menu-item index="/agent/scroll">
              <div class="menu-box">
                <!-- popper-class="popoverStyle" -->
                <el-popover placement="top-start" :width="255" trigger="hover">
                  <template #reference>
                    <div class="m-2 is-plain">
                      资格证分类
                      <el-icon class="el-icon-arrow-down" :class="{
                        down: isDetail,
                        down1: !isDetail,
                      }"></el-icon>
                    </div>
                  </template>
                  <div v-for="(item, index) in popoverContent" :key="item.classifName" class="popover_item"
                    :class="{ hovered: item.hover }" style="cursor: pointer;" @mouseover="setHover(index, true)"
                    @mouseout="setHover(index, false)" @click="certificateClick(index)">
                    {{ item.classifName }}
                  </div>
                  <!--  -->
                </el-popover>
              </div>
            </el-menu-item>
            <!-- <el-menu-item class="ind" index="5">
              <div class="menu-box">关于我们</div>
            </el-menu-item> -->
          </el-menu>
          <el-menu v-else menu-trigger="click" :default-active="activeIndex"
            :class="i == 1 ? 'el-menu-demo ii' : 'el-menu-demo'" mode="horizontal" @select="handleSelect">
            <el-menu-item class="ind" index="/">
              <div class="menu-box">首页</div>
              <div :class="activeIndex == 0 ? 'is_active' : ''"></div>
            </el-menu-item>
            <el-menu-item class="ind" :index="idx.toString()" v-for="(item, idx) in menuList" :key="idx">
              <div>{{ item.certificateName
                }}</div>
              <div :class="activeIndex == idx + 1 ? 'is_active' : ''"></div>
            </el-menu-item>

            <!-- <el-menu-item class="ind" index="5">
              <div class="menu-box" >关于我们</div>
              <div :class="activeIndex == 6?'is_active':''"></div>
            </el-menu-item> -->
          </el-menu>
        </div>
      </div>
      <div class="nav_hot">
        <div class="hot_line">
          <el-image :src="require('@/assets/img/zige/phone2.png')" v-if="isDetail" class="phone"></el-image>
          <el-image class="phone2" :src="require('@/assets/img/zige/phone2.png')" v-if="!isDetail"></el-image>
        </div>
        <div class="line">
          <div :class="{
            hour: isDetail,
            hour2: !isDetail,
          }">
            24h客服热线
          </div>
          <div :class="{
            phone_text: isDetail,
            phone_text2: !isDetail,
          }">
            {{ domainInfo.mobile }}
          </div>
        </div>
      </div>
    </div>
    <div class="nav_line" v-if="isDetail" />
    <div class="right_line" v-if="isDetail" />
    <!-- 登录弹框 -->
    <el-dialog class="loginDialog" :close-on-click-modal="false" title="" :visible.sync="showLogin"
      :before-close="handleClose">
      <new-login v-if="showLogin" :key="newloginKey" @toHome="toHome" @toRegister="toRegister"
        @showForgot="showForgot" />
    </el-dialog>
    <!-- 注册弹框 -->
    <el-dialog :close-on-click-modal="false" class="loginDialog" title="" :visible.sync="showRegister"
      :before-close="handleCloseRegister">
      <new-register v-if="showRegister" :key="registerKey" @handleShowLogin="handleShowLogin" />
    </el-dialog>
    <!-- 忘记密码弹框 -->
    <el-dialog class="loginDialog" title="" :close-on-click-modal="false" width="22%" :visible.sync="showForgotDialog"
      :before-close="handleCloseForgot">
      <for-got :key="forgotKey" @dealForgot="dealForgot" />
    </el-dialog>
    <!-- 赠送优惠券提示弹窗 -->
    <coupon-tips :coupon-tips-visible="couponTipsVisible" :oupon-list="ouponList" @couponTipsClose="couponTipsClose" />
  </div>
</template>
<script>
import { getProjectClassList, getOrgCertificateByName } from "@/api/home";
import { loginOut } from "@/api/user";
import { Know } from "@/api/know";
import couponTips from "@/components/couponTips.vue";
const know = new Know();
import Bus from "@/api/bus";
import Vue from "vue";
import {
  setReady,
  getToken,
  getInfo,
  getDomainLogo,
  getDomain,
} from "@/api/cookies";
import newLogin from "@/views/login/newLogin.vue";
import newRegister from "@/views/login/newRegister.vue";
import forGot from "@/views/login/forget.vue";
export default {
  components: { newLogin, newRegister, forGot, couponTips },
  props: {
    menuList: { type: Array },
    isDetail: { type: Boolean, default: true },
    activeIndex: { type: String, default: "1" },
    i: { type: Number, default: 0 },
    classifId: { type: String, default: '' },
    isIndex: {
      type: Boolean, default: true
    }
  },
  data() {
    return {
      // detailList: [],
      isHover: false,
      popoverContent: [], // 存储下拉框中的数据
      userInfo: null,
      couponTipsVisible: false,
      forgotKey: 0,
      newloginKey: 0,
      registerKey: 0,
      showForgotDialog: false,
      mustBack: null,
      showRegister: false,
      showLogin: false,
      // activeIndex: "1",
      // 导航栏选中颜色样式
      // 输入框
      input: "",
      // 基本资料
      userInfos: {},
      domainInfo: {},
      // 是否有token
      tok: false,
      token: "",
      noRead: 0,
      userId: null,
      logo: "",
      data: {
        name: "我的题库",
        path: "/all/myexamination",
        chil: [
          { name: "学习资料", path: "/all/mydata" },
          { name: "我的题库", path: "/all/myexamination" },
          { name: "我的作业", path: "/all/myTask" },
        ],
      },
      loginInfo: null,
      ouponList: [],
      srcs: 2,
    };
  },

  watch: {
    // 使用这个可以监听data中指定数据的变化,然后触发watch中对应的function的处理
    $route: function (newVal, oldVal) {
      // if(this.activeIndex!='/'){
      this.activeIndex = newVal.path;
      // }
      this.userId = localStorage.getItem("userId");
      if (oldVal.path == "/login" || newVal.path == "/login") {
        this.token = getToken();
        if (this.token && this.token != "") {
          this.tok = true;
        } else {
          this.tok = false;
        }
      }
      if (oldVal.path == "/search") {
        this.input = "";
      }
      /*   { name: "学习资料", path: "/all/mydata" },
            { name: "我的题库", path: "/all/myexamination" },
            { name: "我的作业", path: "/all/myTask" }, */
      if (newVal.path == "/all/mydata") {
        this.data.name = "学习资料";
        this.data.path = "/all/mydata";
      } else if (newVal.path == "/all/myexamination") {
        this.data.name = "我的题库";
        this.data.path = "/all/myexamination";
      } else if (newVal.path == "/all/myTask") {
        this.data.name = "我的作业";
        this.data.path = "/all/myTask";
      }
      this.userInfos = getInfo();

      know.getUnreadInformList(this.userId, undefined).then((res) => {
        this.noRead = res.msg;
      });
    },
  },
  async created() {
    console.log(this.classifId,'this.classifId啥');

   
    // await this.getdetailList();
    await this.getList();
    this.userInfo = getInfo();

    Bus.$on("readChane", () => {
      know.getUnreadInformList(this.userId, undefined).then((res) => {
        setReady(res.msg);
        this.noRead = res.msg;
      });
    });
    Bus.$on("handleLogin", (item) => {
      if (item) {
        this.cacheVal = item;
      }
      this.showLogin = true;
    });
    Bus.$on("toLoginPage", (bol) => {
      this.showLogin = bol;
    });
    Bus.$on("handleRegister", (bol) => {
      this.showRegister = bol;
    });

    this.cacheVal = null;
    // this.activeIndex = this.$route.path;
    this.domainInfo = getDomain();
    this.logo = getDomainLogo();
    this.userInfos = "";
    this.userId = localStorage.getItem("userId");
    this.token = getToken();
    this.userInfos = getInfo();
    know.getUnreadInformList(this.userId, undefined).then((res) => {
      setReady(res.msg);
      this.noRead = res.msg;
    });

    if (this.token && this.token != "") {
      this.tok = true;
    } else {
      this.tok = false;
    }
    this.loginInfo = sessionStorage.getItem("loginInfo");
    if (this.loginInfo == 1 && getInfo()) {
      /* this.getNewUserSendComponList() */
    }
    this.$forceUpdate();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    setHover(index, value) {
      this.popoverContent[index].hover = value;
    },
    certificateClick(index) {
      this.$router.push(`/agent/scroll?id=${index}`)
    },
    async getList() {
      const params = {
        type: 2,
        status: 1,
      };
      const res = await getProjectClassList(params);
      if (res.code == 0) {
        this.popoverContent = res.data.map((item) => ({
          ...item,
          hover: false,
        }));
      }
    },
    getNewUserSendComponList() {
      const data = {
        userid: getInfo().id,
      };
      getNewUserSendComponList(data).then((res) => {
        if (res.code == 0 && res.msg.length > 0) {
          this.couponTipsVisible = true;
        }
      });
    },

    couponTipsClose() {
      this.couponTipsVisible = false;
    },
    open() {
      window.open("http://gxhh.unzs.com", "_blank");
    },

    handleCloseForgot() {
      this.forgotKey += 1;
      this.showForgotDialog = false;
    },
    handleCloseRegister() {
      this.registerKey += 1;
      this.showRegister = false;
    },
    gopage() { },
    mss(index) {
      if (index == 1) {
        this.cacheVal = "";
        this.showLogin = true;
        // this.$router.push("/login");
      }
      if (index == 2) {
        this.showRegister = true;
        // this.$router.push("/register");
      }
    },
    dealForgot() {
      this.showForgotDialog = false;
      this.showLogin = true;
    },
    showForgot() {
      this.showForgotDialog = true;
      this.showLogin = false;
    },
    handleClose() {
      this.newloginKey += 1;
      if (this.cacheVal == "mustBack") {
        this.$router.go(-1);
      }
      this.showLogin = false;
    },
    toRegister() {
      this.cacheVal = "";
      this.showLogin = false;
      this.showRegister = true;
    },
    toHome() {
      this.cacheVal = "";
      this.showLogin = false;
    },
    handleShowLogin(bol) {
      this.showLogin = bol;
      this.showRegister = false;
    },
    gopage(path) {
      const userInfo = getInfo();
      if (!userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
      this.$router.push(path);
    },
    /* 菜单切换 */
    handleSelect(key, keyPath) {
      console.log(key, 'key');
      console.log(keyPath, 'keyPath');
      if (key === "/") {
        this.activeIndex = "0"
      }
      else if (key === "0") {
        this.$emit("selectChange", "0", this.menuList[key])
      } else if (key === "1") {
        this.$emit("selectChange", "1", this.menuList[key])
      } else if (key === "2") {
        this.$emit("selectChange", "2", this.menuList[key])
      } else if (key === "3") {
        this.$emit("selectChange", "3", this.menuList[key])
      } else if (key === "4") {
        this.$emit("selectChange", "4", this.menuList[key])
      } else if (key === "5") {
        this.$emit("selectChange", "5", this.menuList[key])
      }

      if (key == 5) {
        window.open("http://gxhh.unzs.com", "_blank");
        history.go(0)
      } else if (key === "0" && !this.menuList) {
        this.$router.push("/agent/scroll");
        //
      } else {
        if (key === "/") {
          this.$router.push(`${key}`);
          return;
        }
        if (this.menuList) {
          this.$emit("setActiveItem", key);
        }
        // else {
        //   this.$router.push(`${key}`);
        // }
      }
    },
    // 点击logo图片跳转首页
    shouy() {
      this.$router.push({ path: "/" });
    },
    // 点击退出登录
    async clogin() {
      loginOut();
      localStorage.clear();

      this.$router.replace(`/`);
      window.location.reload();
    },
    searchClick() {
      if (location.pathname == "/search") {
        Bus.$emit("search", this.input);
      } else {
        this.$router.replace({ name: "搜索", params: { search: this.input } });
      }
    },
    handleScroll() {
      var yheight1 = window.pageYOffset;
      if (yheight1 > 0) {
        this.srcs = 1;
      } else {
        this.srcs = 2;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.navigationSeventh {
  width: 100%;
  // position: relative;
  z-index: 99;

  .nav {
    width: 1350px;
    height: 87px;
    margin-left: 350px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav2 {
    width: 1400px;
    height: 87px;
    margin-left: 350px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navigator {
    width: 1350px;
    height: 87px;
    margin-left: 350px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
  }

  .nav_left {
    display: flex;
    align-items: center;

    .logo {
      // width: 125px;
      height: 45px;
      margin-right: 50px;

      .logo_img {
        width: 100%;
        height: 100%;
      }
    }

    .nav_item {
      .el-menu-item {
        padding: 0;
      }

      .el-menu-demo,
      .el-menu-demo1 {
        display: flex;
        align-items: center;
        border: none !important;

        /deep/.el-menu-item {
          color: #fff;
          height: 87px;
          line-height: 87px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          background-color: transparent !important;
        }

        /deep/ .is-active {
          border: none;
        }

        .el-icon-arrow-down {
          color: #fff !important;
        }
      }

      .el-menu-demo {
        .el-icon-arrow-down {
          color: #fff !important;
        }

        .el-menu-item {
          margin-right: 40px;
        }

        .el-menu-item:nth-last-child(1) {
          margin-right: 0;
        }

        .ind {
          position: relative;

          .is_active {
            width: 30px;
            height: 3px;
            background-color: #fff;
            border-radius: 3px;
            position: absolute;
            bottom: -3px;
            left: 50%;
            margin-left: -15px;
          }
        }
      }

      .el-menu-demo1 {
        .down1 {
          color: rgba(51, 51, 51, 1) !important;
        }

        .down {
          color: #fff !important;
        }

        .el-menu-item {
          margin-right: 70px;
        }

        .el-menu-item:nth-last-child(1) {
          margin-right: 0;
        }
      }
    }
  }

  .nav_hot {
    display: flex;
    align-items: center;
    margin-left: 58px;
    flex-shrink: 0;

    .hot_line {
      margin-right: 7px;

      .phone {
        width: 34px;
        height: 34px;
      }

      .phone2 {
        width: 44px;
        height: 44px;
      }
    }

    .line {
      .hour {
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #a9b2be;
        letter-spacing: 1px;
        margin-bottom: 4px;
      }

      .hour2 {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #a9b2be;
        letter-spacing: 1px;
      }

      .phone_text {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 1px;
      }

      .phone_text2 {
        font-size: 20px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        letter-spacing: 1px;
      }
    }
  }

  .nav_line {
    width: 100%;
    height: 2px;
    background-color: #fff;
    opacity: 0.1;
    z-index: 5;
  }

  .right_line {
    width: 2px;
    height: calc(100vh - 87px);
    background-color: #fff;
    opacity: 0.1;
    z-index: 5;
    position: absolute;
    right: 127px;
  }
}

.ii {
  .ind {
    color: #333 !important;
  }

  .is-active {
    color: #3D6CC8 !important;
  }
}
</style>
<style>
.el-popover {
  top: 56px !important;
  /* display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center; */
  padding: 20px 20px 0;
  border-radius: 15px;
}

.popover_item {
  margin-bottom: 20px;
  margin-right: 20px;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.popover_item:nth-last-child(1) {
  margin-bottom: 0;
}

.popover_item:nth-child(2n) {
  margin-right: 0;
}

.el-menu-item {
  transition: none;
}
</style>
